<template>
  <div class="store-header-box">
    <!--logo和搜索框区域-->
    <div class="left-box">
      <img :src="logoUrl" style="cursor: pointer;vertical-align: middle" width="128" height="22" @click="goShopping" :alt="$t('key1000010')">
      <div class="line_item" v-if="supplierInfo.shopLogoPath"></div>
      <img class="min-logo" v-if="supplierInfo.shopLogoPath" :src="supplierInfo.shopLogoPath" alt=""/>
      <h3 class="title" :title="supplierInfo.name">{{ supplierInfo.name }}</h3>
      <i class="iconfont collection_icon" :class="collectionTalg ? 'collection-active' : ''"
        @click="collectionBtn" v-if="((getPermission('ymsSupplierMerchant_deleteAssociatedStore') ||
          getPermission('ymsSupplierMerchant_addAssociatedStore')) && userInfo) || !userInfo">&#xe68f;</i>
    </div>
    <div class="right-box">
      <input class="search-input" v-model.trim="searchValue" :placeholder="$t('key1000011')"/>
      <button class="opts-btn search-stations-btn" :title="$t('key1000082')" @click="searchBtn('list')">{{ $t('key1000082') }}</button>
      <button class="opts-btn search-ourstore-btn" :title="$t('key1000083')" style="margin-left: 2px;" @click="searchBtn('store')">{{ $t('key1000083') }}</button>
      <!--消息中心-->
      <news-box :storeTalg="true"></news-box>
    </div>
  </div>
</template>

<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

import newsBox from "@/components/common/newsBox";
import api from '@/api/';
import {getDistributorErpConfig} from "@/utils/user";
import Mixin from "@/components/mixin/common_mixin";

export default {
  name: 'storeHeader',
  mixins: [Mixin],
  data() {
    return {
      logoUrl: require('@/assets/images/logo.png'),
      collectionTalg: false,
      searchValue: this.$route.query.searchValue || '',
      supplierMerchantId: null,
      supplierInfo: {}
    };
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    }
  },
  created() {
    let merchantId = this.userInfo.merchantId;
    if (this.$store.state.distributorInfo) {
      this.supplierMerchantId = this.$store.state.distributorInfo.ymsMerchantId;
    } else {
      this.supplierMerchantId = localStorage.getItem('supplierMerchantId');
    }

    if (merchantId && this.supplierMerchantId) {
      this.isAssociatedStore(merchantId, this.supplierMerchantId);
    }
    if (this.supplierMerchantId) {
      this.getSupplierInfo(this.supplierMerchantId);
    }
  },
  methods: {
    // 收藏店铺
    goShopping() {
      window.location.href = this.$store.state.shoppingInfo.shoppingDomain + '/index.html#/index';
    },
    collectionBtn() {
      let v = this;
      getDistributorErpConfig(true).then((res) => {
        let distributorMerchantId = res.merchant.merchantId;
        if (distributorMerchantId) {
          let query = `?ymsDistributorMerchantId=${distributorMerchantId}&ymsSupplierMerchantId=${v.supplierMerchantId}`;
          // 取消关注
          if (v.collectionTalg) {
            v.axios.post(api.post_ymsSupplierMerchant_deleteAssociatedStore + query).then(response => {
              if (response.data.code === 0) {
                v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000084'));
                v.collectionTalg = false;
              }
            });
          }
          // 关注
          else {
            v.axios.post(api.post_ymsSupplierMerchant_addAssociatedStore + query).then(response => {
              if (response.data.code === 0) {
                v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000084'));
                v.collectionTalg = true;
              }
            });
          }
        }
      });
    },
    // 查询当前当前供应商店铺是否被关注
    isAssociatedStore(merchantId, supplierMerchantId) {
      let v = this;
      let query = `?ymsDistributorMerchantId=${merchantId}&ymsSupplierMerchantId=${supplierMerchantId}`;
      v.axios.get(api.get_ymsSupplierMerchant_isAssociatedStore + query).then(response => {
        if (response.data.code === 0) {
          v.collectionTalg = response.data.datas;
        }
      });
    },

    // 模糊搜索
    searchBtn(type) {
      let path = '';
      // 搜索全站
      let lang = localStorage.getItem('lang');
      if (type === 'list') {
        path = `/index.html#/shopList?lang=${lang}&searchValue=${this.searchValue}`;
        window.location.href = this.$store.state.shoppingInfo.shoppingDomain + path;
      }
      // 搜索本店
      else if (type === 'store') {
        if (this.searchValue) {
          path = `?lang=${lang}&searchValue=${this.searchValue}`;
        } else {
          path = `?lang=${lang}`;
        }
        let url = window.location.origin;
        if (url) {
          window.location.href = url + path;
        }
      }
    },

    // 获取供应商基本信息数据
    getSupplierInfo(supplierMerchantId) {
      let v = this;
      v.axios.get(api.get_ymsSupplierMerchant + supplierMerchantId).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            let newData = data.ymsSupplierMerchantDetail || {};
            data.name = v.handleMultilingualFields(data.name);
            newData = Object.assign({}, data, newData);
            v.supplierInfo = newData;
            v.$store.commit('supplierInfo', newData);
            v.$forceUpdate();
          }
        }
      });
    }
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.searchValue) {
        this.searchValue = this.$route.query.searchValue;
      } else {
        this.searchValue = '';
      }
    }
  },
  components: {
    newsBox
  }
};
</script>

<style lang="less" scoped>
@import "src/assets/styles/shopping/common";

.store-header-box {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  box-sizing: border-box;

  .left-box {
    display: flex;
    align-items: center;

    .line_item {
      width: 1px;
      height: 40px;
      background: #E5E5E5;
      margin: 0 14px 0 30px;
    }

    .min-logo {
      width: 40px;
      height: 40px;
      display: inline-block;
      overflow: hidden;
    }

    .title {
      max-width: 240px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bolder;
      color: #1d1d1d;
      margin-left: 30px;
      font-weight: bolder;
      .ellipsis(1);
    }

    .collection_icon {
      font-size: 18px;
      color: #ccc;
      cursor: pointer;
      margin: 0 10px;
    }

    .collection-active {
      color: @subject_color;
    }
  }

  .right-box {
    display: flex;
    align-items: center;

    .search-input {
      width: 350px;
      height: 40px;
      line-height: 40px;
      color: #AAAAAA;
      background: #EFF0F5;
      border-color: transparent !important;
      box-shadow: none !important;
      outline: none;
      border: none;
      padding: 0 10px;
      font-size: 14px;
    }

    .opts-btn {
      font-size: 14px;
      width: 80px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-weight: 400;
      color: #FFFFFF;
      border: none;
      outline: none;
      cursor: pointer;
      margin-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .search-stations-btn {
      background-color: @subject_color;
    }

    .search-ourstore-btn {
      background-color: #434343;
    }
  }
}
</style>
